/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/style.scss"

import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import $ from 'jquery/dist/jquery.slim'

export const wrapRootElement = ({ element }) => {
  return (
      <GoogleReCaptchaProvider reCaptchaKey="6Lc5biIjAAAAAM72XJOuaCmVbyPh8s0b2gKWI_Aq">
          {element}
      </GoogleReCaptchaProvider>
  )
}

export const onRouteUpdate  = () => {
  function initializeCustomCollapsible() {
    if ($(".custom-collapsible").length <= 0) return
    $(".custom-collapsible .collapsible-header").on("click", function () {

      if ($(this).closest(".collapsible-header").hasClass("open")) {
        $(this).closest(".collapsible-header").removeClass("open")
        $(this).siblings()[0].classList.remove("open")
        

      } else {
        $(".collapsible-header").removeClass("open")
        $(".collapsible-body").removeClass("open")
        $(this).closest(".collapsible-header").addClass("open")
        $(this).siblings()[0].classList.add("open")

      }
    })

    $(".custom-collapsible .collapsible-header").on("focus", function () {

      if ($(this).closest(".collapsible-header").hasClass("open")) {
        $(this).closest(".collapsible-header").removeClass("open")
        $(this).siblings()[0].classList.remove("open")
         

      } else {
        $(".collapsible-header").removeClass("open")
        $(".collapsible-body").removeClass("open")
        $(this).closest(".collapsible-header").addClass("open")
        $(this).siblings()[0].classList.add("open")

      }
    })
      
  }
  function initializeHeader() {
    $(".header-toggle-hamburger").on("click", function () {
      $(this).addClass("open")
      $(".header-toggle-cross").addClass("open")
      $(".mobile-menu-wrapper").addClass("open")
      $("body").addClass("mobile-lock")
    })
    $(".header-toggle-cross").on("click", function () {
      $(this).removeClass("open")
      $(".header-toggle-hamburger").removeClass("open")
      $(".mobile-menu-wrapper").removeClass("open")
      $("body").removeClass("mobile-lock")
    })
    
    if($(".menu-item").hasClass("custom-collapsible")){
      $(".menu-item").on("click", function () {
      if ($(".collapsible-body li").hasClass("active")) {
        $(".header-toggle-cross").removeClass("open")
        $(".header-toggle-hamburger").removeClass("open")
        $(".mobile-menu-wrapper").removeClass("open")
        $("body").removeClass("mobile-lock")
        }
      })
    }
    else{
      $(".menu-item").on("click", function () {
        if ($(".menu-item a").hasClass("active")) {
          $(".header-toggle-cross").removeClass("open")
          $(".header-toggle-hamburger").removeClass("open")
          $(".mobile-menu-wrapper").removeClass("open")
          $("body").removeClass("mobile-lock")
        }
      })
    }
    if($("body").hasClass("mobile-lock"))
    {
      $("body").removeClass("mobile-lock")
    }
  }

  function handleCustomTabs() {
    if ($(".tabs").length <= 0) return
    $(".tabs li").on("click", function () {
      if ($(this).hasClass("active")) return

      $(this).siblings().removeClass("active")
      const tabs = $(this).parents(".tabs").data("controls")
      $(tabs).children().removeClass("active")

      const target = $(this).data("tab")
      const title = $(this).parents(".tabs").data("title")
      if (title != null) $(title).text($(this).text())
      $(target).addClass("active")
      $(this).addClass("active")
    })
  }
  function initializeCustomCollapsiblefaq() {
    if ($(".custom-collapsible-faq").length <= 0) return
    $(".custom-collapsible-faq .collapsible-header-faq").on(
      "click",
      function () {
        const body = $(this).next(".collapsible-body-faq")
        const parent = $(this).parent(".custom-collapsible-faq")
        if (!$(this).hasClass("open")) {
          $(this).addClass("open")
          $(body).css("maxHeight", $(body)[0].scrollHeight + "px")
          if ($(parent).hasClass("accordian")) {
            $(parent)
              .siblings(".custom-collapsible-faq")
              .each(function (index, collapsible) {
                $(collapsible)
                  .find(".collapsible-body-faq")
                  .css("maxHeight", "0px")
                $(collapsible)
                  .find(".collapsible-header-faq")
                  .removeClass("open")
              })
          }
        } else {
          $(body).css("maxHeight", "0px")
          $(this).removeClass("open")
        }
      }
    )
    $(
      ".custom-collapsible-faq .collapsible-header-faq.open ~.collapsible-body-faq"
    ).each(function (index, body) {
      $(body).css("maxHeight", body.scrollHeight + "px")
    })
  }
  function initializeviewing() {
    if ($(".switch-wrapper").length <= 0) return
    $(".switch-wrapper").on("click", function () {
      if ($(".switch-wrapper").hasClass("active")) {
        $(".switch-wrapper").removeClass("active")
        $(".list-wrapper").removeClass("active")
        $(".map-wrapper").addClass("active")
        $(".map-view-text").addClass("active")
        $(".discover").removeClass("active")
        $(".list-view-text").removeClass("active")
      } else {
        $(".switch-wrapper").addClass("active")
        $(".list-wrapper").addClass("active")
        $(".map-wrapper").removeClass("active")
        $(".discover").addClass("active")
        $(".map-view-text").removeClass("active")
        $(".list-view-text").addClass("active")
      }
    })
  }
  $(document).ready(function () {
    initializeCustomCollapsible()
    initializeHeader()
    // initializeDropdown()
    handleCustomTabs()
    initializeCustomCollapsiblefaq()
    initializeviewing()
  })
}
